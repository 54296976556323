import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, KinguinCategory } from './Models';

export const getKinguinCategory = async (
  params: Arguments,
): Promise<KinguinCategory> => {
  const response = await apiClient.get(
    `${ENDPOINTS.KINGUIN}${ENDPOINTS.UNMAPPED_PRODUCT}`,
    {
      params,
    },
  );

  return response.data;
};
