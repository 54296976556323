export enum BrandsFilterField {
  Brands = 'brandId',
  Search = 'searchText',
  Country = 'country',
  Currency = 'currency',
  SuppliersId = 'supplierId',
  IntegrationType = 'isIntegrated',
  IncludeV2 = 'includeV2',
  Status = 'status',
  IncludeArchived = 'includeArchived',
}

export interface FilterInputs {
  [BrandsFilterField.Brands]: string;
  [BrandsFilterField.Search]: string;
  [BrandsFilterField.Country]: string;
  [BrandsFilterField.Currency]: string;
  [BrandsFilterField.SuppliersId]: string;
  [BrandsFilterField.IntegrationType]: string;
  [BrandsFilterField.IncludeV2]?: boolean;
  [BrandsFilterField.Status]?: string;
  [BrandsFilterField.IncludeArchived]?: boolean;
}
