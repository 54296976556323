export enum ProductsFilterField {
  Brands = 'brandId',
  Country = 'country',
  Currency = 'currency',
  SuppliersId = 'supplierId',
  SuppliersName = 'supplierName',
  Denomination = 'denomination',
  Search = 'searchText',
  ProductId = 'productId',
  ExpirationDate = 'expirationDate',
  CardCode = 'cardCode',
  Url = 'url',
  CodeFileReference = 'codeFileReference',
  SupplierInvoiceNumber = 'supplierInvoiceNumber',
  FilterType = 'filterType',
  IncludeArchived = 'includeArchived',
  ProductStatus = 'productStatus',
}

export interface FilterInputs {
  [ProductsFilterField.Brands]: string;
  [ProductsFilterField.Country]: string;
  [ProductsFilterField.Currency]: string;
  [ProductsFilterField.SuppliersId]: string;
  [ProductsFilterField.SuppliersName]: string;
  [ProductsFilterField.Denomination]: string;
  [ProductsFilterField.Search]: string;
  [ProductsFilterField.ProductId]?: number;
  [ProductsFilterField.ExpirationDate]?: string;
  [ProductsFilterField.CardCode]?: string;
  [ProductsFilterField.Url]?: string;
  [ProductsFilterField.CodeFileReference]?: string;
  [ProductsFilterField.SupplierInvoiceNumber]?: string;
  [ProductsFilterField.FilterType]?: string;
  [ProductsFilterField.IncludeArchived]?: boolean;
  [ProductsFilterField.ProductStatus]?: string;
}
