import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Arguments, Response } from './Models';

export const getProductsApi = async (params: Arguments): Promise<Response> => {
  // If productStatus is archived, we need to set IncludeArchived to true and remove productStatus from the params
  if (params.productStatus === 'Archive') {
    params = {
      ...params,
      includeArchived: true,
    };
  }

  const response = await apiClient.get(ENDPOINTS.PRODUCT_LIST, {
    params,
  });

  return response.data;
};
