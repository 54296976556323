import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  KinguinCategory,
  Arguments,
} from '../../../api/kinguin/getKinguinCategory/Models';
import { getKinguinCategory } from 'api/kinguin/getKinguinCategory';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const fetchKinguinCategoryTable = createAsyncThunk<
  KinguinCategory,
  Arguments,
  AsyncThunkOptions
>(
  'eneba/fetchKinguinCategoryTable',
  async (queryParams, { rejectWithValue }) => {
    try {
      return await getKinguinCategory(queryParams);
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        console.error({
          message: error.response.data.message,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      return rejectWithValue(error.response.status);
    }
  },
);
