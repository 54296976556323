export enum NewCatalogField {
  CatalogName = 'name',
  CatalogImage = 'image',
  Description = 'description',
  ClientDiscountType = 'clientDiscountType',
  ClientDiscountValue = 'clientDiscountValue',
  TransactionFeeType = 'transactionFeeType',
  TransactionFeeValue = 'transactionFeeValue',
  ClientDiscountTypeSwitch = 'clientDiscountTypeSwitch',
  TransactionFeeTypeSwitch = 'transactionFeeTypeSwitch',
  SupplierPriceFee = 'supplierPriceFeeValue',
  OverrideCatalogPrices = 'overrideCatalogPrices',
  OverriddenSupplierPriceValue = 'overriddenSupplierPriceValue',
  OverriddenSupplierPriceType = 'overriddenSupplierPriceType',
  ProfitShare = 'overriddenPriceWithSharedProfitValue',
  EnableProfitShare = 'overridePriceWithSharedProfit',
  ProfitShareType = 'overriddenPriceWithSharedProfitType',
}

export interface CatalogInputs {
  [NewCatalogField.CatalogName]: string;
  [NewCatalogField.Description]: string;
  [NewCatalogField.ClientDiscountType]: string | null | undefined;
  [NewCatalogField.ClientDiscountValue]: number;
  [NewCatalogField.TransactionFeeType]: string | null | undefined;
  [NewCatalogField.TransactionFeeValue]: number;
  [NewCatalogField.CatalogImage]: File[] | null;
  [NewCatalogField.ClientDiscountTypeSwitch]: boolean;
  [NewCatalogField.TransactionFeeTypeSwitch]: boolean;
  [NewCatalogField.SupplierPriceFee]: string | number | null;
  [NewCatalogField.OverrideCatalogPrices]: boolean;
  [NewCatalogField.OverriddenSupplierPriceValue]: number;
  [NewCatalogField.OverriddenSupplierPriceType]: string | null | undefined;
  [NewCatalogField.ProfitShare]: number;
  [NewCatalogField.EnableProfitShare]: boolean;
  [NewCatalogField.ProfitShareType]: string;
}
